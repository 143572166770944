<template>
    <div class="card" style="background: white">
        <div class="row mb-3">
            <div class="col-6">
                <Multiselect
                    v-model="selectedCompany"
                    v-show="isAdmin"
                    deselect-label="Unselect"
                    track-by="name"
                    label="name"
                    placeholder="Select company"
                    :options="companies"
                    :searchable="true"
                    :allow-empty="true"
                    :preselect-first="companies.length === 1"
                    :disabled="companies.length === 1"
                />
            </div>
        </div>

        <div class="card">
            <div class="card-body">
                <BTable
                    :items="responseRates"
                    :fields="fields"
                    empty-text="No users"
                    show-empty
                >
                    <template #cell(company_name)="data">
                        {{ data.item.company_name }}
                    </template>

                    <template #cell(date_of_invitation)="data">
                        {{ data.date_of_invitation | formatDate }}
                    </template>
                </BTable>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import { mapState, mapGetters, mapActions, createNamespacedHelpers } from 'vuex'
import dayjs from 'dayjs'
import { STORE_MODULE } from '@/enums'

const { mapState: mapStateAuth } = createNamespacedHelpers(STORE_MODULE.AUTH)

export default {
    name: 'ResponseRate',

    components: { Multiselect },

    computed: {
        ...mapStateAuth(['userAccount']),
        ...mapGetters('responseRates', ['getResponseRates']),

        isAdmin() {
            return this.userAccount && this.userAccount.userRole === 'admin'
        },

        responseRates: (vm) => {
            return vm.getResponseRates.responseRates
        },
    },

    watch: {
        userAccount: {
            async handler(userAccount) {
                if (!userAccount) return

                if (!userAccount.userRole === 'admin') {
                    await this.fetchResponseRates(this.$store.state.companyAssigned)
                } else {
                    this.fetchAllCompanies()
                }
            },
            immediate: true,
        },

        companies() {
            if (this.companies.length > 0) {
                const company = this.companies.find((obj) => obj.id === this.editCompany)
                this.selectedCompany = company || this.companies[0]
            }
        },

        async selectedCompany(val) {
            await this.fetchResponseRates(this.selectedCompany.id)
        },
    },

    data() {
        return {
            companies: [],
            selectedCompany: null,

            fields: [
                { key: 'company_name', label: 'Company' },
                { key: 'email', label: 'e-mail' },
                { key: 'first_name', label: 'First name' },
                { key: 'last_name', label: 'Last name' },
                { key: 'user_role', label: 'Role' },
                {
                    key: 'date_of_invitation',
                    label: 'Date of invitation',
                    formatter: 'formatDateAssigned',
                },
                { key: 'person-who-sent-invite', label: 'Person who sent the invite' },
                { key: 'type_of_invitation', label: 'Type of invitation' },
                { key: 'status', label: 'Status' },
            ],
        }
    },

    filters: {
        formatDate(date) {
            return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
        },
    },

    methods: {
        ...mapActions('responseRates', ['fetchResponseRates']),
        fetchAllCompanies() {
            this.$store.state.backend
                .get('/jobBuilder/company/all')
                .then((ret) => (this.companies = ret.data))
                .catch((error) => alert(error))
        },
    },
}
</script>
<style lang="sass" scoped>
label
  font-size: 14px
  font-weight: bold

table ::v-deep > tbody > tr
  cursor: pointer
</style>
